@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Danger";
  src: url("./fonts/Danger-Agenda.otf") format("opentype");
}
@font-face {
  font-family: "Capi";
  src: url("./fonts/capitellum-regular.ttf") format("opentype");
}
@font-face {
  font-family: "benz";
  src: url("./fonts/Corporate\ A\ Bold.otf") format("opentype");
}
@font-face {
  font-family: "brave";
  src: url("./fonts/bravehunter.ttf") format("opentype");
}
.lausanne {
  font-family: "Lausanne", sans-serif;
}

.sometimes-italic {
  font-family: "Sometimes Times Italic", Times, serif;
}

.sometimes {
  font-family: "Sometimes Times", Times, serif;
}

.danger {
  font-family: "Danger", sans-serif;
}
.capi {
  font-family: "capi", sans-serif;
}
.benz {
  font-family: "benz", sans-serif;
}
.brave {
  font-family: "brave", sans-serif;
}
/* Hide scrollbar for Chrome, Safari, and Opera */
/* ::-webkit-scrollbar {
  display: none;
} */

/* Hide scrollbar for Firefox */
/* * {
  scrollbar-width: none;
  -ms-overflow-style: none;
} */

body {
  margin: 0;
  font-family: "Manrope", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-optical-sizing: auto;
}
